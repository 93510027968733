<template>
    <div class="UserAnaly_A">
        <div class="rana_con">
            <div class="rana_con_title">
                筛选最终确定的目标用户群体
            </div>
            <!-- 内容 -->
            <div class="rana_con_content">
                <div class="arrow_box">
                    <div class="arrow_box_left">
                        <!-- <img src="./../../../assets/img/arrow_left.png" alt="" v-preventReClick @click="prevBox()"> -->
                    </div>
                    <ul class="arrow_box_cente">
                        <li v-for="(item,index) in caseList" :key="index" class="li_up">
                            <div class="tiem_one">
                                主语：{{item.role}}
                            </div>
                            <div class="tiem_all">
                                <div class="tiem_one" v-for="(items,indexs) in item.tag.slice(0,3)" :key="indexs">
                                    <img src="./../../../assets/img/label.png" alt="">
                                    <span class="items_span">{{items}}</span>
                                </div>
                            </div>
                            <img class="line_img" src="./../../../assets/img/line.png" alt="">
                            <div class="line_cont" :title="item.desc2">
                                <span>
                                    {{item.desc2}}
                                </span>
                            </div>
                        </li>
                    </ul>
                    <div class="arrow_box_left">
                        <!-- <img src="./../../../assets/img/arrow_right.png" alt=""  v-preventReClick @click="nexBox()"> -->
                    </div>
                </div>
            </div>
            <!-- 按钮 -->
            <div class="butt_div">
                <!-- <div class="button_one" v-preventReClick @click="prevstep()">
                    <span>取消</span>
                </div> -->
                <div class="button_one" v-preventReClick @click="nextBotn()">
                    <span>返回首页</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            istrue:0,
            caseList:[]
        };
    },
    methods: {
        getapp(){
            this.axios.projectS1P3({
                token:this.$store.state.token,
                id:this.$store.state.cla_id
            }).then(res=>{
                if(res.data.code==0){
                    var str = res.data.data.qrcode
                    this.$emit('info', str);
                    this.caseList = res.data.data.values.filter(el=> el.val==1)
                }else{
                     this.caseList = [] 
                }
            }).catch(err=>{

            })
        },
        nextBotn(){
            this.$router.push({name:'StudentPage'})
        }
    },
    mounted() {
        this.getapp()
    }
};
</script>
<style scoped>
@import './../../../assets/css/student/A/UserAnaly_A.css';
</style>